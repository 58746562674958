html{
  margin: 0px !important;
  padding: 0px !important;
}
body{
  padding: 0px !important;
  margin: 0px !important;
}
.navbar{
  background: #000 !important;
}
.header-lg {
  width: 1780px;
  float: left;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px auto;
}
.navbar-brand{
  width: 300px;
}
.navbar-brand img{
  width: 100%;
  object-fit: cover;
}
.main-menubox {
  display: none;
}

.main-menu:hover .main-menubox {
  display: block;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 3px;
  top: 40%;
}

.dropdown-submenu:hover .dropdown-menu,
.dropdown-submenu:focus .dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute !important;
  margin-top: -30px;
  left: 100%;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

ul.navbar-nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;
  list-style: none;
}

.navbar-collapse {
  margin: 0;
  max-width: 100%;
  width: 100%;
  padding: 0 10%;
}
a.nav-link{
  margin: 0;
  padding: 0;
  color: #fff !important;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}
a:hover.nav-link{
  color: #007bff !important;
}
.nav-link:focus, .nav-link:hover {
  color: #007bff !important;
}

@media (max-width: 992px) {
  .main-menubox {
    display: block; /* Show dropdown on mobile */
  }
  .dropdown-menu {
    width: 50%;
  }

  .dropdown-menu .dropdown-submenu {
    width: auto;
  }

  .dropdown-menu {
    left: -131%;
  }

}

.dropdown-submenu a::after {
  right: 16px;
  top: 42%;
}

.dropdown-item {
  padding: 8px 18px;
  white-space: normal;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  font-size: 14px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #dcdcdc;
}

/* li.nav-item {
  padding: 10px 20px;
} */

.social-media-icons {
  display: flex;
  gap: 15px;
}

.social-icon {
  background-color: #fff;
  border-radius: 50%;
  color: #000;
  display: inline-block;
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: 40px;
}

.social-icon:hover {
  background-color: #007bff;
  color: #fff;
}


/* Footer section */


.py-5 {
  padding: 3rem 0;
}

.mb-3 {
  margin-bottom: 1rem;
}

.nav-link {
  text-decoration: none;
  color: #6c757d;
}

.nav-link:hover {
  text-decoration: underline;
}

.form-control {
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.border-top {
  border-top: 1px solid #dee2e6;
}

.list-unstyled {
  list-style: none;
  padding: 0;
  margin: 0;
}

.link-dark {
  color: #343a40;
  text-decoration: none;
}

.link-dark:hover {
  color: #212529;
}

.footer-section a.nav-link{
  color: #fff !important;
  text-transform: capitalize;
  text-decoration: none;
}
.footer-wapper{
padding: 0 3%;
}
.footer-section {
  width: 100%;
  float: left;
  position: relative;
  padding-top: 60px;
  background: #000;
  color: #fff;
}
.follow_us_ft{
  width: 100%;
  float: left;
  position: relative;
  top: 30px;
}
.ft_sect{
  padding-bottom: 20px;
}
.coppyright{
  width: 100%;
  float: left;
  text-align: center;
}