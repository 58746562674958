/* Ensures that the carousel takes the full width of the viewport */
#carouselExample {
  width: 100%;
  /* You can adjust the height based on your preference */
}

/* Ensures carousel images are responsive and take full width */
.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Center carousel captions and style them */
.carousel-caption {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  text-align: center;
}

.carousel-caption h3 {
  font-size: 2rem;
  font-weight: bold;
}

.carousel-caption p {
  font-size: 1.2rem;
}

/* Add a background gradient to make text more readable on bright images */
.carousel-item {
  position: relative;
}

.carousel-item img {
  filter: brightness(60%);
}

/* Responsive Design  */
.header {
  font-size: 2em;
  color: #333;
}

/* Adjust for tablets */
@media (max-width: 768px) {
  .navbar-brand {
    width: 230px;
  }
}

/* Adjust for mobile */
@media (max-width: 480px) {
  .navbar-brand {
    width: 200px;
  }
}

/* ===================================== About Us======================================== */
.about_section {
  width: 100%;
  float: left;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}
.about_hmp_pic img {
  width: 100%;
  object-fit: cover;
  border-radius: 80px 0px;
}
.about_ttl {
  width: 100%;
  float: left;
  position: relative;
  padding-bottom: 30px;
}
.about_ttl img {
  width: 60%;
}
.about_ttl h5 {
  text-transform: uppercase;
}
.about_ttl img {
  width: 60%;
  position: relative;
  top: -25px;
}
.about_content {
  width: 100%;
  float: left;
  position: relative;
}